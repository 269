<template>
  <vca-card>
    <h2>{{ $t("events.add.header") }}</h2>
    <EventAdd />
  </vca-card>
</template>

<script>
import EventAdd from "@/components/events/EventAdd";
import { mapGetters } from "vuex";
export default {
  name: "EventsAdd",
  components: { EventAdd },
  created() {
    this.$store.dispatch({ type: "crews/public" });
    this.$store.dispatch({ type: "users/list" });
    this.$store.dispatch({ type: "artists/list" });
    this.$store.dispatch({ type: "organizers/list" });
    this.$store.dispatch({ type: "organisations/list" }).then(() => {
      if (
        !this.hasSystemPermission() &&
        this.hasPoolPermission(this.poolEventPermissions)
      ) {
        this.create.crew_id = this.user.crew.crew_id;
        this.create.organisation_id = this.user.crew.organisation_id;
        this.create.internal_asp = this.organisations.find((el) => {
          return el.id == this.user.crew.organisation_id;
        }).default_asp;
      } else if (this.hasSystemPermission()) {
        this.create.organisation_id = this.user.organisation_id;
        this.create.internal_asp = this.organisations.find((el) => {
          return el.id == this.user.organisation_id;
        }).default_asp;
      }
    });
  },
  computed: {
    create: {
      set(value) {
        this.$store.commit("events/create", value);
      },
      get() {
        return this.$store.state.events.create;
      },
    },
    ...mapGetters({
      user: "user/current",
      organisations: "organisations/list",
      poolEventPermissions: "user/roles/poolEventPermissions",
      hasPoolPermission: "user/roles/hasPoolPermission",
      hasSystemPermission: "user/roles/hasSystemPermission",
    }),
  },
};
</script>
