<template>
  <div>
    <vca-field :label="$t('organisation.context')">
      <vca-row>
        <vca-dropdown
          v-model="organisation"
          :options="organisationList"
          ref="organisation"
          label=""
          :placeholder="$t('events.organisation.placeholder')"
          :errorMsg="$t('events.organisation.errorMsg')"
        ></vca-dropdown>
      </vca-row>
    </vca-field>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "OrganisationSelect",
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  validations() {
    return this.validation;
  },
  methods: {
    validate() {
      this.$refs.organisation.validate();
    },
  },
  computed: {
    organisation: {
      set(value) {
        let id = value.length ? value[0].value : null;
        let newVal = JSON.parse(JSON.stringify(this.value));
        newVal["organisation_id"] = id;
        this.$emit("input", newVal);
      },
      get() {
        return this.$store.state.organisations.list.filter((el) => {
          el.title = el.name;
          el.label = el.name;
          el.value = el.id;
          return el.id == this.value.organisation_id;
        });
      },
    },
    organisationList: {
      get() {
        return this.organisations.filter((el) => {
          el.title = el.name;
          el.label = el.name;
          el.value = el.id;
          return true;
        });
      },
    },
    ...mapGetters({
      user: "user/current",
      organisations: "organisations/list",
    }),
  },
};
</script>
