<template>
  <div>
    <Spinner v-if="isLoading" />
    <vca-field
      v-else-if="value.internal_asp"
      :label="$t('events.internal_asp.header')"
    >
      <div>{{ $t("events.internal_asp.description") }}</div>
      <div>
        <h3>{{ $t("events.internal_asp.select.label") }}</h3>
        <vca-dropdown
          ref="internal_asp"
          v-model="internal_asp"
          :options="internalAspList"
          :rules="$v.value.internal_asp"
          label=""
          :errorMsg="$t('events.internal_asp.select.errorMsg')"
          :placeholder="$t('events.internal_asp.select.placeholder')"
        />
      </div>
      <div v-if="value.internal_asp.id">
        <div>
          <h3>{{ $t("events.internal_asp.name.label") }}</h3>
          <vca-input
            ref="full_name"
            disabled
            v-model="full_name"
            :errorMsg="$t('events.internal_asp.name.errorMsg')"
            :placeholder="$t('events.internal_asp.name.placeholder')"
          />
        </div>
        <div>
          <h3>{{ $t("events.internal_asp.email.label") }}</h3>
          <vca-input
            ref="email"
            disabled
            v-model="email"
            :errorMsg="$t('events.internal_asp.email.errorMsg')"
            :placeholder="$t('events.internal_asp.email.placeholder')"
          />
        </div>
        <div>
          <h3>{{ $t("events.internal_asp.phone.label") }}</h3>
          <vca-input
            ref="phone"
            v-model="phone"
            :errorMsg="$t('events.internal_asp.phone.errorMsg')"
            :placeholder="$t('events.internal_asp.phone.placeholder')"
          />
        </div>
      </div>
    </vca-field>
  </div>
</template>

<script>
import Spinner from "@/components/utils/Spinner.vue";
import { mapGetters } from "vuex";
export default {
  name: "InternalAsp",
  components: { Spinner },
  data() {
    return {
      isLoading: false,
      internalAspList: [],
    };
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  validations() {
    return this.validation;
  },
  mounted() {
    this.updateInternalAspList();
  },
  watch: {
    "value.organisation_id": {
      handler(val) {
        if (val != undefined && val != "") {
          this.updateInternalAspList();
        }
      },
      deep: true,
    },
    organisations: {
      handler(val) {
        if (val && val.length > 0 && this.internalAspList.length > 0) {
          const default_asp = val.filter((org) => {
            return org.id == this.value.organisation_id;
          });
          if (default_asp != undefined) {
            this.internal_asp = [
              this.internalAspList.find(
                (el) => el.id == default_asp.default_asp_id
              ),
            ];
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    validate() {
      this.$refs.internal_asp.validate();
    },
    updateInternalAspList() {
      this.isLoading = true;
      let data = {
        system_roles: ["employee", "pool_employee"],
        organisation_id: this.value.organisation_id
          ? this.value.organisation_id
          : this.user.organisation_id,
      };
      if (
        this.hasSystemPermission() &&
        this.value.crew &&
        this.value.crew.organisation_id
      ) {
        data.organisation_id = this.value.crew.organisation_id;
      }

      this.$store
        .dispatch({ type: "users/queryList", data: data })
        .then((response) => {
          this.internalAspList = response.filter((el) => {
            el.title = el.full_name;
            el.label = el.full_name;
            el.subtitle = el.display_name + " <" + el.email + ">";
            el.value = el.id;
            if (this.organisations && this.organisations.length > 0) {
              const default_asp = this.organisations.find((org) => {
                return org.id == this.value.organisation_id;
              });
              if (default_asp != undefined) {
                if (el.id == default_asp.default_asp_id) {
                  this.internal_asp = [el];
                }
              }
            }
            return true;
          });
          this.isLoading = false;
        });
    },
  },
  computed: {
    full_name() {
      return this.value.internal_asp.full_name;
    },
    email() {
      return this.value.internal_asp.email;
    },
    phone: {
      get() {
        return this.value.internal_asp.profile.phone;
      },
      set(value) {
        this.value.phone = value;
      },
    },
    internal_asp: {
      get() {
        return [{ value: this.value.internal_asp.id }];
      },
      set(value) {
        let newValue = this.value;
        if (value[0] != undefined) {
          newValue.internal_asp_id = value.length > 0 ? value[0].id : "";
          newValue.internal_asp = value.length > 0 ? value[0] : "";
        }
        this.value = newValue;
      },
    },
    userSelection() {
      return this.usersList.filter((el) => {
        el.title = el.full_name;
        el.label = el.full_name;
        el.subtitle = el.display_name + " <" + el.email + ">";
        el.value = el.id;
        return true;
      });
    },
    ...mapGetters({
      user: "user/current",
      organisations: "organisations/list",
      usersList: "users/list",
      validation: "events/validations",
      hasSystemPermission: "user/roles/hasSystemPermission",
    }),
  },
};
</script>
