<template>
  <div>
    <EventForm v-model="create" ref="form" />
    <vca-card
      class="event-form-error"
      v-if="invalidFields.length > 0"
      v-html="errorMessages"
    ></vca-card>
    <div>
      <button class="vca-button" @click.prevent="add()">
        {{ $t("context_button.save", { 0: $t("context.draft") }) }}
      </button>
      <button class="vca-button-small" @click.prevent="publish()">
        {{ $t("button.publish") }}
      </button>
    </div>
  </div>
</template>

<script>
import EventForm from "@/components/events/form/EventForm";
import { mapGetters } from "vuex";
export default {
  name: "EventAdd",
  components: { EventForm },
  data() {
    return {
      invalidFields: [],
    };
  },
  methods: {
    validate() {
      this.$refs.form.validate();
      this.invalidFields = Object.keys(this.$v.create.$params).filter(
        (fieldName) => this.$v.create[fieldName].$invalid
      );
    },
    prepareSave() {
      if (this.hasSystemPermission()) {
        this.create.event_state.internal_confirmation = this.user.id;
      } else if (this.hasPoolPermission(this.poolEventPermissions)) {
        this.create.event_state.crew_confirmation = this.user.id;
      }
      if (this.create.type_of_event == "crew_meeting") {
        this.create.application.start_date = parseInt(Date.now() / 1000);
        this.create.application.end_date = this.create.start_at;
        this.create.application.supporter_count = 999;
      }
    },
    publish() {
      if (this.isInvalid) {
        this.validate();
      } else {
        if (!confirm(this.$t("events.messages.publish"))) {
          return false;
        }
        this.invalidFields = [];
        this.prepareSave();
        this.create.event_state.state = "published";
        this.$store.dispatch({ type: "events/create" }).then(() => {
          this.$store.commit("events/resetCreate");
        });
      }
    },
    add() {
      if (this.$v.create.type_of_event.$invalid) {
        this.invalidFields = ["type_of_event"];
        this.$refs.form.validate_type();
        return false;
      }
      this.$store.dispatch({ type: "events/create" }).then(() => {
        this.$store.commit("events/resetCreate");
        this.$router.push({ path: "/events/edit/" + this.current.id });
      });
    },
  },
  created() {
    this.create.creator_id = this.user.id;
  },
  validations() {
    return this.validation;
  },
  computed: {
    errorMessages() {
      return this.invalidFields
        .map((el) => this.$t("events." + el + ".errorMsg"))
        .join("<br/>");
    },
    create: {
      set(value) {
        this.$store.commit("events/create", value);
      },
      get() {
        return this.$store.state.events.create;
      },
    },
    isInvalid() {
      if (this.hasSystemPermission()) {
        // TOUR is currently disabled
        if (this.create.type_of_event == "tour") {
          return (
            this.$v.create.type_of_event.$invalid ||
            this.$v.create.name.$invalid ||
            this.$v.create.start_at.$invalid ||
            this.$v.create.end_at.$invalid
          );
        } else if (this.create.type_of_event == "crew_meeting") {
          return (
            this.$v.create.type_of_event.$invalid ||
            this.$v.create.name.$invalid ||
            this.$v.create.start_at.$invalid ||
            this.$v.create.end_at.$invalid
          );
        } else {
          return (
            this.$v.create.type_of_event.$invalid ||
            this.$v.create.location.$invalid ||
            this.$v.create.name.$invalid ||
            this.$v.create.application.$invalid ||
            this.$v.create.start_at.$invalid ||
            this.$v.create.end_at.$invalid
          );
        }
      } else {
        return this.$v.create.$invalid;
      }
    },
    ...mapGetters({
      isCopy: "events/isCopy",
      event: "events/create",
      validation: "events/validations",
      user: "user/current",
      current: "events/current",
      isCreateTourType: "events/isCreateTourType",
      poolEventPermissions: "user/roles/poolEventPermissions",
      hasPoolPermission: "user/roles/hasPoolPermission",
      hasSystemPermission: "user/roles/hasSystemPermission",
    }),
  },
};
</script>
